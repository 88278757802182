import React from 'react';

const Dates = ({data}) => {
  return (
    <div>
    <article className="my-1 flex justify-end items-end  ">
        <ul>
          <li><span className="font-bold ">Order ID:</span> {data?.order?.orderId}</li>
          <li><span className="font-bold ">Order date:</span> {data?.order?.orderDate.split('T')[0]}</li>
          <li><span className="font-bold ">Payment Status:</span> {data?.order?.paymentStatus}</li>
        </ul>
      </article>
    </div>
  );
}

export default Dates;
